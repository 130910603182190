import React, { useState, useEffect } from 'react';
import './dashboard.css';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';

import StockSelector from './stockSelector';
import TimeframeSelector from './timeframeSelector'
import BarChart from './barChart';

import UserService from '../services/userService';
import MarketDataService from '../services/marketDataService';

export default function Dashboard({ location }) {
  const [componentWatchlist, setComponentWatchlist] = useState([]);
  useEffect(() => {
    UserService.getWatchlist().then((watchlist) => {
      setComponentWatchlist(watchlist);
    });
  }, []);

  const receiveSelectedSymbol = (selectedSymbol) => {
    if (!componentWatchlist.includes(selectedSymbol)) {
      const newComponentWatchlist = [...componentWatchlist, selectedSymbol];
      updateWatchlist(newComponentWatchlist);
    }
  };

  const removeSymbol = (symbol) => {
    const newComponentWatchlist = componentWatchlist.filter(
      (item) => item !== symbol
    );
    updateWatchlist(newComponentWatchlist);
  };

  const updateWatchlist = (newComponentWatchlist) => {
    UserService.updateWatchlist(newComponentWatchlist).then(
      (updatedWatchlist) => {
        setComponentWatchlist(updatedWatchlist);
      }
    );
  };

  const [componentTimeframe, setComponentTimeframe] = useState('');
  const receiveSelectedTimeframe = (selectedTimeframe) => {
    setComponentTimeframe(selectedTimeframe);
  }

  const [componentBars, setComponentBars] = useState({});
  useEffect(() => {
    if (componentWatchlist.length && componentTimeframe) {
      MarketDataService.getBars(componentWatchlist, componentTimeframe).then((bars) => {
        setComponentBars(bars);
      });
    }
  }, [componentWatchlist, componentTimeframe]);

  return (
    <div className="dashboard">
      <StockSelector reportSelectedSymbol={receiveSelectedSymbol} />

      <TimeframeSelector reportSelectedTimeframe={receiveSelectedTimeframe} />

      <Tabs defaultActiveKey="home">
        <Tab eventKey="home" title="Home">
          {componentWatchlist.map((symbol) => {
            return (
              <BarChart
                key={symbol}
                symbol={symbol}
                barData={componentBars[symbol]}
              />
            );
          })}
        </Tab>
        {componentWatchlist.map((symbol) => {
          return (
            <Tab
              key={symbol}
              eventKey={symbol}
              title={
                <>
                  {symbol}
                  <Button variant="danger" onClick={() => removeSymbol(symbol)}>
                    X
                  </Button>
                </>
              }
            >
              <BarChart
                key={symbol}
                symbol={symbol}
                barData={componentBars[symbol]}
              />
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}
