import ApolloClient from 'apollo-boost';
import { gql } from 'apollo-boost';

var ApolloService = (function () {
  const getClient = () => {
    return new ApolloClient({
      uri: process.env.REACT_APP_DATA_SERVICE_URL,
    });
  };

  var barsQuery = async function (symbolsString, timeframe) {
    const response = await getClient().query({
      query: gql`
        query Bars($symbols: String!, $timeframe: String!) {
          bars(symbols: $symbols, timeframe: $timeframe) {
            time
            open
            high
            low
            close
            volume
            symbol
          }
        }
      `,
      variables: {
        symbols: symbolsString,
        timeframe: timeframe,
      },
    });
    return response['data']['bars'];
  };

  var getAssets = async function (inputValue) {
    const response = await getClient().query({
      query: gql`
        query Assets($inputValue: String!) {
          assets(search: $inputValue, limit: 10) {
            name
            symbol
          }
        }
      `,
      variables: {
        inputValue: inputValue,
      },
    });
    return response['data']['assets'];
  };

  var userQuery = async function () {
    const response = await getClient().query({
      query: gql`
        {
          users {
            username
          }
        }
      `,
    });
    return response['data']['users'][0]; // dev placeholder
  };

  var watchlistQuery = async function (username) {
    const response = await getClient().query({
      query: gql`
        query Profile($username: String!) {
          profile(username: $username) {
            watchlist
          }
        }
      `,
      variables: {
        username: username,
      },
    });
    return response['data']['profile']['watchlist'];
  };

  var watchlistMutation = async function (username, newWatchlist) {
    const response = await getClient().mutate({
      mutation: gql`
        mutation UpdateProfile($username: String!, $newWatchlist: [String!]) {
          updateProfile(username: $username, watchlist: $newWatchlist) {
            profile {
              watchlist
            }
          }
        }
      `,
      variables: {
        username: username,
        newWatchlist: newWatchlist,
      },
    });
    return response['data']['updateProfile']['profile']['watchlist'];
  };

  return {
    getAssets: getAssets,
    userQuery: userQuery,
    watchlistQuery: watchlistQuery,
    watchlistMutation: watchlistMutation,
    barsQuery: barsQuery,
  };
})();

export default ApolloService;
