import ApolloService from './apolloService';

var UserService = (function () {
  var locallyStoredUser = null;

  var getWatchlist = async function () {
    const username = await getUsername();
    const watchlist = await ApolloService.watchlistQuery(username);
    return watchlist;
  };

  var updateWatchlist = async function (newWatchlist) {
    const username = await getUsername();
    const updatedWatchlist = await ApolloService.watchlistMutation(
      username,
      newWatchlist
    );
    return updatedWatchlist;
  };

  var getUsername = async function () {
    const user = await UserService.getUser();
    return user['username'];
  };

  var getUser = async function () {
    if (locallyStoredUser) {
      return new Promise((resolve, reject) => {
        resolve(locallyStoredUser);
      });
    } else {
      const userResponse = await ApolloService.userQuery();
      locallyStoredUser = userResponse
      return locallyStoredUser;
    }
  };

  return {
    getUser: getUser,
    getUsername: getUsername,
    getWatchlist: getWatchlist,
    updateWatchlist: updateWatchlist,
  };
})();

export default UserService;
