import React, { useState, useEffect } from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

// const timeframes = [
//   { name: '1m', value: '1m' },
//   { name: '5m', value: '5m' },
//   { name: '15m', value: '15m' },
//   { name: '1h', value: '1h' },
//   { name: '6h', value: '6h' },
//   { name: '1d', value: '1d' },
// ];

const timeframes = [
  { name: '1Min', value: '1Min' },
  { name: '5Min', value: '5Min' },
  { name: '15Min', value: '15Min' },
  { name: '1D', value: '1D' },
];

export default function TimeframeSelector({ reportSelectedTimeframe }) {
  const [selectedTimeframe, setSelectedTimeframe] = useState('15Min');
  useEffect(() => {
    reportSelectedTimeframe(selectedTimeframe);
  }, [selectedTimeframe, reportSelectedTimeframe]);

  const handleClick = (event) => {
    setSelectedTimeframe(event.target.value);
  };

  return (
    <ButtonGroup size="sm">
      {timeframes.map((timeframe) => {
        return (
          <Button
            variant={
              selectedTimeframe === timeframe['value']
                ? 'primary'
                : 'outline-primary'
            }
            value={timeframe['value']}
            onClick={handleClick}
          >
            {timeframe['name']}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
