import React from 'react';

import { createChart } from 'lightweight-charts';

export default class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartElement = React.createRef();
  }
  chart = null;
  lineSeries = null;
  volumeSeries = null;

  componentDidMount() {
    this.chart = createChart(this.chartElement.current, {
      width: 400,
      height: 300,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
      },
    });
    this.lineSeries = this.chart.addCandlestickSeries();
    this.volumeSeries = this.chart.addHistogramSeries({
      color: '#D3D3D3',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
  }

  componentDidUpdate(prevProps) {
    let newBarData = this.props.barData;
    if (prevProps.barData !== newBarData) {
      this.lineSeries.setData(newBarData);
      const volumeData = newBarData.map((dataPoint) => {
        return { time: dataPoint.time, value: dataPoint.volume };
      });
      this.volumeSeries.setData(volumeData);
    }
  }

  render() {
    return <div ref={this.chartElement} />;
  }
}
