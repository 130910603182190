import React from 'react';

import AsyncSelect from 'react-select/async';

import ApolloService from '../services/apolloService';

var pendingOptions = [];
const loadOptions = (inputValue, callback) => {
  pendingOptions.forEach((pendingOption) => {
    clearTimeout(pendingOption);
  });
  var timer = setTimeout(() => {
    ApolloService.getAssets(inputValue).then((assets) => {
      let assetOptions = assets.map((assetOption) => {
        return {
          label: `${assetOption['symbol']} - ${assetOption['name']}`,
          value: assetOption['symbol'],
        };
      });
      callback(assetOptions);
    });
  }, 1000);
  pendingOptions.push(timer);
};

export default function StockSelector({ reportSelectedSymbol }) {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption['value'];
    reportSelectedSymbol(selectedValue);
  };

  return (
    <div className="stockSelector">
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        placeholder="Search ticker or name..."
        onChange={handleChange}
      />
    </div>
  );
}
