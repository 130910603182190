import ApolloService from './apolloService';

var MarketDataService = (function () {
  var getBars = async function (symbols, timeframe) {
    const symbolsString = symbols.join(',');
    const barsResponse = await ApolloService.barsQuery(
      symbolsString,
      timeframe
    );
    let grouppedBars = barsResponse.reduce((output, bar) => {
      bar['time'] = dateToChartTimeMinute(bar['time']);
      output[bar.symbol] = [...(output[bar.symbol] || []), bar];
      return output;
    }, {});
    return grouppedBars;
  };

  var dateToChartTimeMinute = (dateString) => {
    const date = new Date(dateString);
    return (
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0,
        0
      ) / 1000
    );
  };

  return {
    getBars: getBars,
  };
})();

export default MarketDataService;
